<template>
  <v-app>
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-header">
            <div class="card-title pt-3 px-3 d-flex justify-content-between">
              <div class="breadcrumb-left">
                <h4>
                  Survey questions
                </h4>
                <div class="breadcrumb-sub-header">
                  <router-link to="/dashboard">Dashboard </router-link> \ Survey questions
                </div>
              </div>
              <div class="breadcrumb-right">
                <div class="card-toolbar">
                  <v-btn
                      @click="manageSurveyGroup()"
                      text
                      x-large
                  >
                   Manage survey group
                  </v-btn>
                  <v-btn @click="createQuestionnaire()" class="mt-1 btn btn-primary" style="color: #fff">
                    <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                    Add new
                  </v-btn>
                </div>
              </div>
            </div>
          </div>
          <div class="card-body">
            <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
              <div class="row">
                <v-col cols="12" sm="6" md="3">
                  <v-text-field
                      label="Search by survey question"
                      v-model="search.question"
                      outlined
                      dense
                      v-on:keyup.enter="searchQuestionnaires()"
                      @input="search.question = $event !== null ? $event : ''"
                      clearable
                  ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="2">
                  <v-autocomplete
                      :items="categories"
                      item-text="name"
                      item-value="id"
                      label="Category"
                      clearable :loading="categoryLoading"
                      v-model="search.category_id"
                      v-on:keyup.enter="searchQuestionnaires()"
                      @input="search.category_id = $event !== null ? $event : ''"
                      dense
                      outlined>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="3">
                  <v-autocomplete
                      :items="survey_groups"
                      item-text="title"
                      item-value="id"
                      label="Survey group"
                      clearable :loading="surveyGroupLoading"
                      v-model="search.survey_group_id"
                      v-on:keyup.enter="searchQuestionnaires()"
                      @input="search.survey_group_id = $event !== null ? $event : ''"
                      dense
                      outlined>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" md="2">
                  <v-select
                      label="Status"
                      v-model="search.is_active"
                      v-on:keyup.enter="searchQuestionnaires"
                      @input="search.is_active = $event !== null ? $event : ''"
                      :items="status"
                      item-text="name"
                      item-value="value"
                      clearable
                      outlined
                      dense
                  ></v-select>
                </v-col>


                <v-col cols="2" class="text-right">
                  <v-btn
                      :loading="loading"
                      @click.prevent="searchQuestionnaires()"
                      class="btn btn-primary w-35 float-right"
                      dark
                  >
                    <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                    Search
                  </v-btn>
                </v-col>

              </div>
            </div><!-- table -->
            <div class="table-responsive">

              <v-skeleton-loader
                  type="table-thead"
                  v-if="loading"
              >
              </v-skeleton-loader>

              <v-skeleton-loader
                  v-if="loading"
                  type="table-row-divider@25"
              >
              </v-skeleton-loader>

              <table class="table" v-if="! loading">
                <thead>
                <tr class="px-3">
                  <th class="px-3">Title</th>
                  <th class="px-3">Survey group</th>
                  <th class="px-3">Category</th>
                  <th class="px-3">Mandatory</th>
                  <th class="px-3">Status</th>

                  <th class="pr-3 text-center">Options</th>
                </tr>
                </thead>

                <tbody>
                  <tr v-for="(item, index) in questionnaires" :key="index">
                    <td  class="px-3">
                      <p style="font-size: 1.1em; line-height: 1.6; color: #333;  word-wrap: break-word; white-space: normal;
                          margin: 0; " class="" v-html="getLimitedText(item.question, 50)">
                      </p>
                    </td>
                    <td class="px-3">
                      <span class="font-weight-medium">
                        {{ item.survey_group_title }}
                      </span>
                    </td>
                    <td class="px-3">
                        <ul v-if="item.category_names_in_array">
                          <li v-for="category in item.category_names_in_array">
                            {{category }}
                          </li>
                        </ul>
                        <span v-else>
                          NA
                        </span>
                      </td>
                    <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': item.is_required, 'badge-warning': !item.is_required }">{{ item.is_required ? 'Yes' : 'No' }}</span>
                    </td>
                    <td class="px-3">
                        <span class="badge"
                              v-bind:class="{ 'badge-success': item.is_active, 'badge-warning': !item.is_active }"
                        >{{ item.is_active ? 'Active' : 'In active' }}</span>
                    </td>
                    <td class="pr-0 text-left">
                      <template>
                        <b-dropdown
                            size="sm"
                            variant="link"
                            toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                            no-caret
                            right
                            no-flip>
                          <template v-slot:button-content>
                            <i class="ki ki-bold-more-hor"></i>
                          </template>
                          <!--begin::Navigation-->
                          <div class="navi navi-hover min-w-md-250px">
                            <b-dropdown-text tag="div" class="navi-item">
                              <a  class="navi-link"  @click="editQuestionnaire(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-edit"></i>
                                  </span>
                                <span class="navi-text">Edit</span>
                              </a>
                            </b-dropdown-text>
                            <b-dropdown-text tag="div" class="navi-item">
                              <a class="navi-link"  @click="deleteQuestionnaire(item)">
                                  <span class="navi-icon">
                                      <i class="fas fa-trash"></i>
                                  </span>
                                <span class="navi-text">Delete</span>
                              </a>
                            </b-dropdown-text>
                          </div>
                        </b-dropdown>
                      </template>
                    </td>
                  </tr>
                  <tr v-if="questionnaires.length === 0">
                    <td class="text-center px-3" colspan="8">No items found.</td>
                  </tr>
                </tbody>
              </table>
              <b-pagination
                  v-if="questionnaires.length > 0"
                  class="pull-right mt-7"
                  @input="getAllQuestionnaires"
                  v-model="page"
                  :total-rows="total"
                  :per-page="perPage"
                  first-number
                  :disabled="loading"
                  last-number
              ></b-pagination>
            </div>
            <!-- table ends -->

          </div>
        </div>
      </div>
      <create-and-update ref="create-update-questionnaire"  @refresh="getAllQuestionnaires"></create-and-update>
    </div>
  </v-app>
</template>
<script>
import QuestionnaireService from "@/services/questionnaire/QuestionnaireService";
import CreateAndUpdate from "./CreateAndUpdate";
import CategoryService from "@/services/product/category/CategoryService";
import SurveyGroupService from "@/services/survey-groups/SurveyGroupService";

const questionnaire=new QuestionnaireService();
const surveyGroup=new SurveyGroupService();
const category = new CategoryService();
export default {
  components: {
    CreateAndUpdate
  },
  data(){
    return{
      search:{
        question:null,
        category_id:null,
        survey_group_id:null,
        is_active:'',
      },
      categories:[],
      categoryLoading:false,

      survey_groups:[],
      surveyGroupLoading:false,
      questionnaires:[],
      total: null,
      perPage: null,
      page: null,
      loading: true,
      status: [
        { name: 'Active', value: '1'},
        { name: 'Inactive', value: '0'},
      ],
    }
  },
  mounted() {
    this.getAllCategories();
    this.getAllSurveyGroups();
    this.getAllQuestionnaires();
  },
  methods:{
    manageSurveyGroup(){
      this.$router.push({
        name: "survey-group",
      });
    },
    getAllCategories() {
      this.categoryLoading=true;
      category
          .all()
          .then(response => {
            this.categories = response.data.categories;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.categoryLoading = false;
          });
    },
    getAllSurveyGroups() {
      this.surveyGroupLoading=true;
      surveyGroup
          .getAllActiveSurveyGroup()
          .then(response => {
            this.survey_groups = response.data.surveyGroups;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.surveyGroupLoading = false;
          });
    },
    getLimitedText(text, wordLimit) {
      let words = text.split(" ");
      if (words.length > wordLimit) {
        return words.slice(0, wordLimit).join(" ") + " ...";
      }
      return text;
    },
    getAllQuestionnaires(){
      this.loading=true;
      questionnaire
          .paginate(this.search, this.page)
          .then(response => {
            this.questionnaires=response.data.data;
            this.page = response.data.meta.current_page;
            this.total = response.data.meta.total;
            this.perPage = response.data.meta.per_page;
          })
          .catch((err) => {

          })
          .finally(() => {
            this.loading = false;
           });
    },
    createQuestionnaire(){
      this.$refs['create-update-questionnaire'].createQuestionnaire();
    },
    searchQuestionnaires(){
      this.page=1;
      this.getAllQuestionnaires();
    },
    deleteQuestionnaire(item){
      this.$confirm({
        message: `Are you sure? `,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: (confirm) => {
          if (confirm) {
            this.loading = true;
            questionnaire
                .delete(item.id)
                .then((response) => {
                  this.loading = false;
                  this.getAllQuestionnaires();
                  this.$snotify.success("Survey question deleted");
                })
                .catch((err) => {
                  this.loading = false;
                  this.$snotify.error("Oops something went wrong");
                });
          }
        },
      });
    },
    editQuestionnaire(item){
      this.$refs['create-update-questionnaire'].editIQuestionnaire(item);
    },
  }
}

</script>